import { isValueInObject, normalizeHost } from '@marketplace-web/shared/utils'
import { Hostname } from 'constants/hostnames'

// TODO: remove this reexport
export {
  transformAbsoluteUrlToRelative,
  toUrlQuery,
  toParams,
  toNextjsParams,
  urlWithParams,
  linkifyString,
  isPrSandboxHostname,
  normalizedQueryParam,
  isCurrentUrl,
  getLastPathnameParam,
  getPathnameParam,
  normalizeHost,
  removeParamsFromQuery,
} from '@marketplace-web/shared/utils'

export const isUrlRelative = (urlToTest: string) => {
  const origin = 'https://www.vinted.com'

  if (urlToTest.startsWith(origin)) return false

  return new URL(urlToTest, origin).origin === origin
}

export const isInternalHostname = (host: string): host is Hostname =>
  isValueInObject(host, Hostname)

export function isInternalUrl(url: string) {
  if (isUrlRelative(url)) return true

  try {
    const { hostname, protocol } = new URL(url)

    if (protocol !== 'http:' && protocol !== 'https:') return false

    return isInternalHostname(normalizeHost(hostname))
  } catch {
    return false
  }
}
